import React from 'react';

import Layout from '../components/Layout';
import {Card, MicroCard, ImageCard} from '../components/Card';


import MarketingImage from '../assets/images/icon-marketing.svg';
import HumanResourcesImage from '../assets/images/icon-employees.svg';
import FinanceImage from '../assets/images/icon-finance.svg';
import SolutionsImage from '../assets/images/icon-cloudready.svg';

import CustomerServiceImage from '../assets/images/icon-customerservice.svg';
import InventoryImage from '../assets/images/icon-inventory.svg';
import SalesImage from '../assets/images/icon-sales.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import CloudImage from '../assets/images/icon-cloudenable.svg';
import GraphiteLogo from '../assets/images/graphitegtc-logo-gr.svg';
import D365Logo from '../assets/images/D365-logo-gr.png';
import TransformImage from '../assets/images/icon-transform.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';


const herobg = '#1c2841', herofcolor='#efefef';
const SolutionsPage = () => (
  <Layout>
    <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                        <h3 >Business Solutions on the Cloud</h3>
                        
                        <h1 style={{letterSpacing:'.1em'}}> Solve tomorrow's challenges by bringing customers and business processes 
                        together, today.</h1>
                </header>
        </div>
        <div className='row col-8 col-12-medium inner' style={{margin:'.25em auto'}}>
                <div className='col-9 col-12-medium' style={{marginTop:'.5em auto 1em'}}>
                        <span className="image left">
                                <img src={SolutionsImage} alt="Business Solutons on the cloud" />
                        </span>
                        <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                                We understand that accounting and business process management tools are vital to the 
                                success of any organization. For businesses, seeking greater financial visibility and 
                                operational productivity, we have enabled them by provisioning ERP solutions 
                                and empowered them move to the cloud. 
                                <br /><br />
                                We provide consulting, customization and systems integration services for CRM & ERP 
                                implementations. Our comprehensive offerings are designed to maximize each 
                                solution's strengths and capabilities to meet your organization's unique needs.
                        </p>
                        <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                                We specialize in the implementation of
                        </p>  
                        <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'.5em auto'}}>
                                <Card className='col-6' cardTitle="Marketing" cardImage={MarketingImage}>
                                        Campaign, Event, Lead, Prospects, Identify & Target, 
                                        Collaborate, Integrate with Sales, Online & Social Media
                                </Card>
                                <Card className='col-6' cardTitle="Sales" cardImage={SalesImage}>
                                        Deals, Collaborate, Conversion rate, Relationship, 
                                        Plan, Forecast, Performance, Accountability & Insights
                                </Card>
                                <Card className='col-6' cardTitle="Supply Chain" cardImage={InventoryImage}>
                                        Planning, production, inventory, warehouse, logistics,  
                                        AI insights and Internet of Things (IoT).
                                </Card>
                                <Card className='col-6' cardTitle="Finance" cardImage={FinanceImage}>
                                        Chart of Accounts, P&L, Cashflow, planning, insights, 
                                        governance, complaince, multi country & language
                                </Card>
                                <Card className='col-6' cardTitle="Customer Service" cardImage={CustomerServiceImage}>
                                        Chat, SMS, Facebook, WhatsApp, seemless
                                        Routing, Collaborate, Productivity, Performance
                                </Card>
                                <Card className='col-6' cardTitle="Human Resources" cardImage={HumanResourcesImage}>
                                        Profile, Training, Performance, absence, benefits,
                                        compensation, payroll, taxes & insights
                                </Card>
                        </div>
                </div>
                <div className='col-3 col-6-medium col-12-small' >
                        <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Explore</p>
                        <MicroCard className='col-12 col-6-medium' 
                                cardTitle="Transformation" cardImage={TransformImage} 
                                cardLink='/transform-your-business'/>
                        <MicroCard className='col-12 col-6-medium' 
                                cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                                cardLink='/public-sector-services'/>
                        <MicroCard className='col-12 col-6-medium' 
                                cardTitle="Talent Services" cardImage={TalentImage} 
                                cardLink='/talent-services'/>
                        <MicroCard className='col-12 col-6-medium' 
                                cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                                cardLink='/lowcode-nocode'/>
                        <MicroCard className='col-12 col-6-medium' 
                                cardTitle="Enable the Cloud" cardImage={CloudImage} 
                                cardLink='/enable-the-cloud'/>
                        <MicroCard className='col-12 col-6-medium' 
                                cardTitle="Modernize" cardImage={ModernizeImage} 
                                cardLink='/modernize-solutions'/>
                        
                        <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em 0'}}>Technology Partners</p>
                        <ImageCard className='col-12 col-6-medium' cardImage={D365Logo} height='35px'/>
                        <ImageCard className='col-12 col-6-medium' cardImage={GraphiteLogo} height='20px'/>
                        
                
                </div>
        </div>
        
    </div>
  </Layout>
);

export default SolutionsPage;
